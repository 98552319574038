.red {
  background-color: red;
}
.blue {
  background-color: blue;
}
.pink {
  background-color: pink;
}
.orange {
  background-color: orange;
}

.bg-primary {
  background-color: #3084ab !important;
}
/* -------------- Home - Carousel -------------- */
.carousel-inner {
  max-height: 450px;
}

.carousel-img-container {
  max-height: 450px;
}

.carousel-caption {
  text-shadow: 0px 0px 2px rgba(0,0,0,1);
}

.flex-equal > * {
  flex: 1;
}

/* -------------- Home - Grid Container -------------- */

.grid-container a {
  all: unset;
  cursor: pointer;
}
.grid-container a:hover {
  text-decoration: underline;
}

.box-column {
  padding: 5rem 3rem 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.box-info-wrapper {
  text-align: center;
  padding-bottom: 1.5rem;
}
.box-info-wrapper p {
  margin: 0;
}

.box-title {
  margin-bottom: 1.5rem;
}

.box-lead {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.box-image-wrapper {
  padding-top: 30px;
  position: relative;
  height: 100%;
  
}

.box-image-relative {
  min-height: 300px;
  height: 100%;
}

.box-image-absolute {
  position: absolute; 
  bottom: 0;
  height: 300px;
  width: 100%;
}

.box-image {
  width: 100%;
  height: 100%;
  border-radius: 21px 21px 0 0;
}

.grid-bg-dark {
  background-color: #f4f3f7;
}

@media (max-width: 992px) {
  .box-image {
    border-radius: 21px 21px 21px 21px;
  }
  .box-column {
    padding: 5rem 3rem 3rem;
  }
}

/* -------------- General -------------- */

.custom-container {
  max-width: 950px;
  padding-bottom: 10em;
}

/* -------------- About us -------------- */

.about-us-info-container {
  padding-bottom: 3em;
}
.about-us-info-container h1 {
  padding: 2em 0 1em;
  text-align: center;
}

.about-us-info-container img{
  margin-bottom: 1em;
}

.about-us-officers-container h4 {
  padding-bottom: 1em;
}

.about-us-officers-table {
  padding-bottom: 3em;
}

/* -------------- Breeders -------------- */

.breeders-info-container {
  padding-bottom: 1.5em;
}

.breeders-info-container h1 {
  padding: 2em 0 1em;
  text-align: center;
}

.breeders-info-legend-table-container {
  margin-left: 5em;
  padding: 0.5em 0;
}

.breeders-info-table {
  padding-bottom: 3em;
  font-size: 0.9em;;
  
}

/* -------------- Events -------------- */

.events-info-container {
  padding-bottom: 3em;
}

.events-info-container h1 {
  padding: 2em 0 1em;
  text-align: center;
}
 .events-header-container h4 {
  border-bottom: 1px solid lightgray;
  margin-bottom: 1em;
  padding: 0.35em;
 }
.events-posts-container strong {
  font-size: 0.9em;
}

/* -------------- Gallery -------------- */
.gallery-info-container {
  padding-bottom: 3em;
}

.gallery-info-container h1 {
  padding: 2em 0 1em;
  text-align: center;
}

.gallery-img-container {
  height: 235px;
  border: 10px solid white;
  position: relative;
  overflow: hidden;
}

.gallery-img {
  position: absolute;
  margin: auto;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0;
}

/* -------------- AKC Standard -------------- */

.akc-standard-info-container {
  padding-bottom: 3em;
}

.akc-standard-info-container h1 {
  padding: 2em 0 1em;
  text-align: center;
}

.akc-standard-info-container h5 {
  padding-bottom: 0.5em;
  margin-top: 1.5em;
  margin-bottom: 1em;
  border-bottom: 1px solid lightgrey;
}

.akc-standard-info-container h6 {
  text-decoration: underline;
}

/* -------------- Footer -------------- */

.footer-container {
  border-top: 5px solid #3ba0d0;
}

/* -------------- Media -------------- */

@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}
@media (max-width: 768px) {
  /* .footer-section {
    display: none;
  } */
}